var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"loading":_vm.loadingTable,"headers":_vm.headers,"items":_vm.audits,"sort-by":"calories","page-count":_vm.numberOfPagesPaginate,"page":_vm.currentPagePaginate,"server-items-length":_vm.totalStagesPaginate,"options":_vm.options,"footer-props":{
      itemsPerPageText: 'Itens por pagina',
      itemsPerPageOptions: [5, 10, 15],
      showFirstLastPage: true,
    }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Auditorias")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"disabled":_vm.already_audited,"color":"primary","dark":"","rounded":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlus)+" ")]),_vm._v(" Auditar ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('div',{staticClass:"title_body"},[_c('span',{staticClass:"text_title"},[_vm._v("Confirmação de Auditoria - Fechamento do Dia")]),_c('span',{staticClass:"btn_close"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){_vm.dialog = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiClose)+" ")])],1)],1)]),_c('v-card-text',[_c('v-container',[_c('v-card-text',[_c('v-row',[_c('v-col',[_c('span',{staticClass:"text-body-1"},[_vm._v("Você está prestes a confirmar a auditoria para o fechamento do dia. Deseja continuar?")])])],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeDialog}},[_vm._v(" Fechar ")]),_c('v-btn',{attrs:{"loading":_vm.loading,"color":"primary"},on:{"click":_vm.storeAudit}},[_vm._v(" Sim ")])],1)],1)],1)],1)]},proxy:true},{key:"item.date",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")]}},{key:"item.hour",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatTime(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.exportRdsPdf(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiFileChartOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Rds")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.exportDailyPdf(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiOfficeBuilding)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Lançamentos de Diárias")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"secondary"},on:{"click":function($event){return _vm.exportItensPdf(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiSilverwareVariant)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v("Lançamentos de Produtos/Serviços")])])],1)]}},{key:"no-data",fn:function(){return [_c('NoDataTable')]},proxy:true},{key:"loading",fn:function(){return [_vm._v(" Carregando... ")]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }